import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './index.sass';

class Feature extends Component {

    static defaultProps = {
        ico: null,
        char: {
            text: '',
            subtext: ''
        }
    };

    selectIcon = () => {
        if (!this.props.char.ico) return false;
        import(`../feature/icons/${this.props.char.ico}`).then((component) => {
            this.setState({iconComponent: component.default})
        });
    };

    state = {
        iconComponent: null
    };

    componentDidMount() {
        this.selectIcon();
    }

    render() {
        return (
            <div className="feature__char">
                <div className="char-ico">
                    <img src={this.state.iconComponent || ''}/>
                </div>
                <div className="char-desc">
                    <div className="char-text">{this.props.char.text}</div>
                    <div className="char-subtext">{this.props.char.subtext}</div>
                </div>
            </div>
        );
    }
}

Feature.propTypes = {
    char:  PropTypes.object.isRequired,
    color: PropTypes.string.isRequired
};

export default Feature;
