import React, {Component} from 'react';
import TweenLite from 'gsap/TweenMax';
import Logo from '../logo';
import './index.sass';
import { Link } from 'react-scroll';
import { ReactComponent as ArrowDown } from './img/arrow.svg';

class InitialScreen extends Component {

    constructor(props) {
        super(props);
        this.state = { x: 0, background: 40 };
        this.leftBush = null;
        this.rightBush = null;
    }



    _onMouseMove(e) {
        this.setState({ x: e.screenX });

        const halfWidth = window.outerWidth / 2;
        const diff = 350;
        const shift = 200;

        this.BushTween = TweenLite.to(
            [this.leftBush, this.rightBush],
            4,
            {x:
                (this.state.x >= halfWidth + diff) ? -shift :
                    (this.state.x <= halfWidth - diff) ? shift : 0
            }
        );

        (this.state.x >= halfWidth + diff) ? this.setState({background: 0}) :
            (this.state.x <= halfWidth - diff) ? this.setState({background: 80}) :
                this.setState({background: 40});
    }

    render() {
        const options = {
            className: 'arrow-link',
            delay: 100,
            offset: 0,
            smooth: true,
            duration: 1000
        };
        return (
            <div className="initial-screen"
                 onMouseMove={this._onMouseMove.bind(this)}
                 style={{backgroundPositionX: this.state.background + '%'}}>
                <div className="initial-screen__badge">
                    <span>Чистые <br/>технологии</span>
                </div>
                <Logo />
                <div className="initial-screen__town"/>
                <div className="initial-screen__cloud-wrap">
                    <div className="initial-screen__clouds"/>
                </div>
                <div className="initial-screen__grass"/>
                <div className="initial-screen__l-tree"/>
                <div className="initial-screen__l-bush" ref={div => this.leftBush = div}/>
                <div className="initial-screen__r-tree"/>
                <div className="initial-screen__r-bush" ref={div => this.rightBush = div}/>
                <div className="initial-screen__mouse-wrap">
                    <Link to="advantages" {...options}>
                        <ArrowDown className="scroll-arrow" />
                    </Link>
                </div>
            </div>
        );
    }
}

export default InitialScreen;
