import React, {Component} from 'react';
import classNames from 'classnames';
import { Link } from 'react-scroll';
import './index.sass';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sticky: null
        };
        this.menuWrapper = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('scroll', this.isSticky);
    }

    isSticky = () => {
        let offset = this.menuWrapper.current.getBoundingClientRect().bottom;
        (offset < 0) ?
            this.setState({sticky: true}) :
            this.setState({sticky: false});
    };

    render() {
        const options = {
            className: 'menu__item',
            spy: true,
            activeClass: 'active',
            delay: 100,
            offset: -100,
            smooth: true,
            duration: 1000
        };
        const menuClasslist = classNames({
            'menu__nav': true,
            'sticky': this.state.sticky
        });
        return(
            <div className="menu">
                <div className="menu__wrapper" ref={this.menuWrapper}>
                    <div className="menu__title-wrap">
                        <h1 className="menu__title">Лакокрасочные продукты <strong className="menu__subtitle">AvantGarde</strong></h1>
                        <div className={menuClasslist}>
                            <Link to="interior-item" {...options}> Краска моющаяся <br/> интерьерная</Link>
                            <Link to="facade-item" {...options}> Краска <br/> фасадная </Link>
                            <Link to="parquet-item" {...options}> Лак <br/> паркетный </Link>
                            <Link to="yacht-item" {...options}> Лак <br/>яхтный </Link>
                            <Link to="wood-item" {...options}> Покрытие <br/>для дерева </Link>
                            <Link to="bio-item" {...options}> BIO эмаль </Link>
                            <Link to="grunt-item" {...options}> Грунт-эмаль </Link>
                            <Link to="protector" {...options}> Серия <br/>Protector</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Menu;