import React from 'react';
import './index.sass';

const Advantages = () => {
    return(
        <div className="advantages">
            <div className="advantages__item item-ecology">
                <div className="advantages__desc">
                    <span className="advantages__title">Экологично</span>
                    <span className="advantages__hover">
                        Низкое содержание летучих <br/>органических веществ
                    </span>
                </div>
            </div>
            <div className="advantages__item item-economy">
                <div className="advantages__desc">
                    <span className="advantages__title">Экономично</span>
                    <span className="advantages__hover">
                        Дешевле, чем <br/>импортные аналоги
                    </span>
                </div>
            </div>
            <div className="advantages__item item-effective">
                <div className="advantages__desc">
                    <span className="advantages__title">Эффективно</span>
                    <span className="advantages__hover">
                        Оптимальная стоимость <br/> <span>1 м<sup>2</sup> покрытия</span>
                    </span>
                </div>
            </div>
        </div>
    )
};

export default Advantages;
