import React from 'react';
import './index.sass';

const Footer = () => {
    return(
        <div className="footer">
            <div className="footer__wrapper">
                <a href="http://www.empils.ru/" target="_blank"><div className="footer__logo"/></a>
            </div>
        </div>
    )
};

export default Footer;
