export const cities = {
  khabarovsk: '48.480223, 135.071917',
  orenburg: '51.768199, 55.096955',
  kirov: '58.603591, 49.668014',
  penza: '53.195042, 45.018316',
  saratov: '51.533557, 46.034257',
  samara: '53.195873, 50.100193',
  toliatti: '53.507836, 49.420393',
  nizhnyNovgorod: '56.326797, 44.006516',
  nabereznyeChelny: '55.741004, 52.407031',
  kazan: '55.796127, 49.106405',
  ufa: '54.735147, 55.958727',
  ulianovsk: '54.314192, 48.403123',
  irkutsk: '52.289588, 104.280606',
  barnaul: '53.346785, 83.776856',
  omsk: '54.989342, 73.368212',
  novosibirsk: '55.030199, 82.920430',
  kemerovo: '55.355198, 86.086847',
  novokuznetsk: '53.757547, 87.136044',
  yekaterinburg: '56.838011, 60.597465',
  chelyabinsk: '55.159897, 61.402554',
  perm: '58.010450, 56.229434',
  tumen: '57.152985, 65.541227',
  surgut: '61.254035, 73.396221',
  arkhangelsk: '64.539911, 40.515753',
  petrozavodsk: '61.785017, 34.346878',
  kaliningrad: '54.710157, 20.510137',
  saintPetersburg: '59.939095, 30.315868',
  lipetsk: '52.608826, 39.599229',
  yaroslavl: '57.626559, 39.893804',
  moscow: '55.753215, 37.622504',
  orel: '52.970756, 36.064358',
  tver: '56.859625, 35.911851',
  tula: '54.193122, 37.617348',
  cherepovetz: '59.122612, 37.903470',
  vologda: '59.220501, 39.891523',
  bryansk: '53.243556, 34.363425',
  ryazan: '54.629560, 39.741908',
  kostroma: '57.767961, 40.926858',
  taganrog: '47.220640, 38.914713',
  rostov: '47.222078, 39.720349',
  krasnodar: '45.035470, 38.975313',
  astrakhan: '46.347614, 48.030178',
  volgograd: '48.707067, 44.516975',
  stavropol: '45.043311, 41.969110',
  voronezh: '51.660786, 39.200269',
  anapa: '44.894818, 37.316367',
  novorossiysk: '44.723771, 37.768813'
};

export const mapData = [
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.khabarovsk,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.orenburg,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.kirov,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.penza,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.saratov,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.samara,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.toliatti,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.nabereznyeChelny,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.kazan,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.ufa,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.ulianovsk,
  },
  {
    name: 'Роща',
    link: 'https://roscha.net/catalog/?q=avantgarde',
    coords: cities.irkutsk,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.irkutsk,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.barnaul,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.omsk,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.novosibirsk,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.kemerovo,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.novokuznetsk,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.yekaterinburg,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.chelyabinsk,
  },
  {
    name: 'Уралстройсервис',
    link: 'http://www.uss74.ru/search ',
    coords: cities.chelyabinsk,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.perm,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.tumen,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.arkhangelsk,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.petrozavodsk,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.kaliningrad,
  },
  {
    name: 'Мегаполис Балтика',
    link: 'https://www.megapolys.com/catalog/?q=avantgarde&s=%D0%9F%D0%BE%D0%B8%D1%81%D0%BA',
    coords: cities.kaliningrad,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.saintPetersburg,
  },
  {
    name: 'Добрострой',
    link: 'https://xn--e1afhbv7b.xn--90afqsbambik.xn--p1ai/?digiSearch=true&term=avantgarde&params=%7Csort%3DDEFAULT',
    coords: cities.lipetsk,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.yaroslavl,
  },
  {
    name: 'Центр СМ',
    link: 'https://yaroslavl.tdcsm.ru/brand/avantgarde/',
    coords: cities.yaroslavl,
  },
  {
    name: 'КРАСКИ.ru',
    link: 'https://www.kraski.ru/search/?q=avantgarde',
    coords: cities.yaroslavl,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.moscow,
  },
  {
    name: 'Радуга краски',
    link: 'https://radugakraski.ru/search/?keyword=avantgarde',
    coords: cities.moscow,
  },
  {
    name: 'КРАСКИ.ru',
    link: 'https://www.kraski.ru/search/?q=avantgarde',
    coords: cities.moscow,
  },
  {
    name: 'Добрострой',
    link: 'https://xn--e1amhi.xn--90afqsbambik.xn--p1ai/?digiSearch=true&term=avantgarde&params=%7Csort%3DDEFAULT',
    coords: cities.orel,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.tver,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.tula,
  },
  {
    name: 'Центр СМ',
    link: 'https://cherepovets.tdcsm.ru/brand/avantgarde/',
    coords: cities.cherepovetz,
  },
  {
    name: 'Центр СМ',
    link: 'https://tdcsm.ru/brand/avantgarde/',
    coords: cities.vologda,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.ryazan,
  },
  {
    name: 'Центр СМ',
    link: 'https://kostroma.tdcsm.ru/brand/avantgarde/',
    coords: cities.kostroma,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.kostroma,
  },
  {
    name: 'Первый строительный гипермаркет DIY1.RU',
    link: 'https://diy1.ru/catalog/?q=avantgarde&s=%D0%9F%D0%BE%D0%B8%D1%81%D0%BA',
    coords: cities.taganrog,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.rostov,
  },
  {
    name: 'КРАСКИ.ru',
    link: 'https://www.kraski.ru/search/?q=avantgarde',
    coords: cities.rostov,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.krasnodar,
  },
  {
    name: 'Добрострой',
    link: 'https://xn--90afqsbambik.xn--p1ai/?digiSearch=true&term=avantgarde&params=%7Csort%3DDEFAULT',
    coords: cities.astrakhan,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.volgograd,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.stavropol,
  },
  {
    name: 'Leroy Merlin',
    link: 'https://leroymerlin.ru/search/?q=avantgarde',
    coords: cities.voronezh,
  },
  {
    name: 'КРАСКИ.ru',
    link: 'https://www.kraski.ru/search/?q=avantgarde',
    coords: cities.voronezh,
  },
  {
    name: 'КРАСКИ.ru',
    link: 'https://www.kraski.ru/search/?q=avantgarde',
    coords: cities.anapa,
  },
  {
    name: 'КРАСКИ.ru',
    link: 'https://www.kraski.ru/search/?q=avantgarde',
    coords: cities.novorossiysk,
  },
];