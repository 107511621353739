import React, {Component} from 'react';
import { YMInitializer } from 'react-yandex-metrika';

class Metrika extends React.Component {
  render() {
    return (
      <div>
        <YMInitializer accounts={[75930538]} />
      </div>
    );
  }
};

export default Metrika;