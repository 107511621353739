import React, { Component } from 'react';
import './index.sass';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import MaskedInput from 'react-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask';

const SITE_KEY = "6LdTXqoUAAAAAOQ5QVQyg4ByVkAcuvWSUZi07_6M";

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            question: '',
            isVerify: false,
        };
        this.reCaptcha = React.createRef();
        this.submitBtn = React.createRef();
        this.form = React.createRef();
        this.formSuccess = React.createRef();
    }

    onSubmit = e => {
        e.preventDefault();
        if (this.state.isVerify) {
            let form = new FormData();
            let {name, email, phone, question} = this.state;
            form.set('name', name);
            form.set('email', email);
            form.set('phone', phone);
            form.set('question', question);

            axios.post('/mail.php', form, {
                headers: {'Content-Type': 'multipart/form-data'}
            }).then(response => {
                console.log(response);
                this.form.current.classList.add('hidden');
                this.formSuccess.current.classList.add('shown');
            }).catch(error => {
                console.log(error);
            })
        } else {
            this.submitBtn.current.classList.toggle('err');
        }
    };

    handleChange = () => {
        this.setState({ isVerify: true });
    };

    render() {
        let mask = (rawValue) => {
            return emailMask;
        };

        return(
            <div className="form">
                <div className="form__wrapper">
                    <div ref={this.formSuccess} className="form__success">
                        <div className="success-title">Вопрос успешно отправлен!</div>
                        <span>Мы свяжемся с вами в ближайшее время</span>
                    </div>
                    <div className="form__container" ref={this.form}>
                        <div className="form__title">
                            Задать вопрос
                        </div>
                        <div className="form__subtitle">
                            Оставьте сообщение и мы свяжемся с Вами в ближайшее время
                        </div>
                        <form className="form__group" onSubmit={e => this.onSubmit(e)}>
                            <div className="form__row">
                                <div className="form__input name">
                                    <input type="text"
                                           required
                                           name="name"
                                           onChange={e => this.setState({name: e.target.value })}/>
                                    <label>Имя</label>
                                </div>
                                <div className="form__input email">
                                    <MaskedInput
                                        mask={mask()}
                                        required
                                        name="e-mail"
                                        onChange={e => this.setState({email: e.target.value })}
                                    />
                                    <label>E-mail</label>
                                </div>
                                <div className="form__input phone">
                                    <MaskedInput
                                        type={'tel'}
                                        mask={['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                                        guide={true}
                                        keepCharPositions={true}
                                        placeholderChar={'\u2000'}
                                        name="phone"
                                        required
                                        onChange={e => this.setState({phone: e.target.value })}
                                    />
                                    <label>Телефон</label>
                                </div>
                            </div>
                            <div className="form__row question-row">
                                <div className="form__input question">
                                    <input type="text"
                                           required
                                           name="question"
                                           onChange={e => this.setState({question: e.target.value })}
                                    />
                                    <label>Вопрос</label>
                                </div>
                            </div>
                            <div className="recaptcha-container">
                                <ReCAPTCHA
                                    ref={this.reCaptcha}
                                    sitekey={SITE_KEY}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form__agreement">
                                Нажимая кнопку «Отправить», я даю согласие на <span>обработку персональных данных</span>
                            </div>
                            <input type="submit"
                                   ref={this.submitBtn}
                                   className="form__button"
                                   value="Отправить"/>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;
