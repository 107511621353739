import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.sass';
import Feature from '../feature';

const CatalogItem = ({info}) => {

    function* values(obj) {
        for (let prop of Object.keys(obj))
            yield obj[prop];
    }

    let arr = Array.from(values(info.chars)); // в массив характеристик

    const infoClass = classNames({
        'catalog-item__info': true,
        'right-info': info.position === 'right'
    });

    const imageClass = classNames({
        'catalog-item__image': true,
        'right-image': info.position === 'right',
        'big-tank': info.color === 'int-protector' || info.color === 'fa-protector'
    });

    const title = React.createRef();
    const overlay = React.createRef();

    function hoverTitle() {
        title.current.classList.toggle('active');
        overlay.current.classList.toggle('show');

    }

    return(
        <div className={'catalog-item ' + info.color+'-item'}>
            <div className={infoClass}>
                <a href="#" className="catalog-item__title" ref={title}>
                    {info.title}
                </a>
                <div className="catalog-item__desc">
                    {info.description}
                </div>
                <div className="catalog-item__chars">
                    {arr.map((char, i) => {
                        return <Feature key={i} char={char} color={info.color}/>
                    })}
                </div>
            </div>
            <div className={imageClass}>
                <a href={info.link}
                   className="catalog-item__link"
                   onMouseOver={hoverTitle}
                   onMouseOut={hoverTitle}
                   target="_blank"
                >
                    <div className="catalog-item__bg" style={{backgroundImage: 'url('+require(`${info.background}`)+')' }}>
                        <div className="catalog-item__overlay" ref={overlay}>
                            <span>Подробная <br/>характеристика</span>
                        </div>
                    </div>
                    <img className="catalog-item__tank" src={require(`${info.pic}`)} alt="" />
                </a>
            </div>
        </div>
    )
};

CatalogItem.propTypes = {
    info: PropTypes.object.isRequired
};


export default CatalogItem;
