import React from 'react';
import './index.sass';

const Social = () => {
    return (
        <div className="social">
            <ul className="social__list">
                <li className="social__item">
                    <a className="social__link social__link--youtube" href="https://www.youtube.com/channel/UCEUBvoPbq15T4U-MNUH8zXQ"
                       target="_blank" title="Youtube"></a>
                </li>
                <li className="social__item">
                    <a className="social__link social__link--rutube" href="https://rutube.ru/channel/24672142/"
                       target="_blank" title="Rutube"></a>
                </li>
            </ul>
        </div>
    )
};

export default Social;
