import React, { useRef } from 'react';
import { YMaps, Map, Placemark, Clusterer, ZoomControl, SearchControl, GeolocationControl } from "react-yandex-maps";
import { cities, mapData } from "./map-data";
import './index.sass';

const WhereToBuy = () =>  {

  //Установим начальное состояние карты
  const mapState = {
    center: cities.moscow.split(',').map(Number),
    zoom: 12,
    behaviors: ['drag'],
    controls: [],
  };

  //Добавим точки
  let placemarks =  [];

  for(let i = 0; i < mapData.length; i++) {
    let balloonText;

    if(mapData[i].link) {
      balloonText = '<a href="' + mapData[i].link + '" target="_blank">Перейти в каталог</a>';
    } else {
      balloonText = 'Для ознакомления с каталогом посетите магазин.';
    }

    placemarks.push(<Placemark modules={['geoObject.addon.balloon']} geometry={mapData[i].coords.split(',').map(Number)} properties={{balloonContentHeader : mapData[i].name, balloonContent: balloonText}} options={{preset: 'islands#blueCircleIcon'}}/>)
  }

  //настроим определение местоположения
  const map = useRef();

  const myPanTo = (coordinates) => {
    map.current.panTo(coordinates, {
      duration: 2000,
      flying: false,
    });
  };

  const getLocationCoords = ymaps => {
    return ymaps.geolocation
      .get({ provider: "yandex", mapStateAutoApply: true })
      .then(result =>
        ymaps.geocode(result.geoObjects.position).then(res => {
          return res.geoObjects.get(0).geometry.getCoordinates();
        })
      );
  };

  const moveToUserLocation = ymaps => {
    getLocationCoords(ymaps).then(result => {
      myPanTo(result);
    })
  };

    //выведем карту
    return(
    <div className="where-to-buy">
      <span className="where-to-buy__title">Где купить?</span>
      <YMaps query={{apikey: '33fb5ed3-3a67-4866-ac08-9a411547e653'}}>
        <div>
          <Map state={mapState}
               modules={["geolocation", "geocode"]}
               className='where-to-buy__map'
               instanceRef={map}
               onLoad={ymaps => moveToUserLocation(ymaps)}
          >
            <ZoomControl/>
            <SearchControl options={{
              provider: 'yandex#search',
              noPlacemark: true,
              noPopup: true,
              noSuggestPanel: true
            }}/>
            <GeolocationControl/>
            <Clusterer
              options={{
                preset: 'islands#blueClusterIcons',
                clusterIconColor: '#1f99ff',
                clusterHideIconOnBalloonOpen: false,
                geoObjectHideIconOnBalloonOpen: false,
                clusterDisableClickZoom: true,
                clusterBalloonContentLayout: 'cluster#balloonAccordionContent'
              }}
              modules={['clusterer.addon.balloon']}
            >
              {placemarks}
            </Clusterer>
          </Map>
        </div>
      </YMaps>
    </div>
    )
}

export default WhereToBuy;
