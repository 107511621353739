import React, { Component } from 'react';
import './App.sass';
import InitialScreen from './components/initial-screen';
import Advantages from './components/advantages';
import Menu from './components/menu';
import Catalog from './components/catalog';
import Protector from './components/protector';
import EmpilsLink from './components/empils-link';
import About from './components/about';
import Form from './components/form';
import Footer from './components/footer';
import Metrika from './components/metrika';
import ReactGA from './components/analytics';
import WhereToBuy from './components/where-to-buy';
import Social from './components/social';

class App extends Component {
  render() {
    return(
        <React.Fragment>
          <InitialScreen />
          <Advantages />
          <Menu />
          <Catalog />
          <Protector />
          <WhereToBuy />
          <About />
          <Form />
          <Social />
          <Footer />
          <Metrika />
        </React.Fragment>
    );
  }
}

export default App;
