import React from 'react';
import './index.sass';

const About = () => {
    return(
        <div className="about">
            <div className="about__wrapper">
                <div className="about__logo"/>
                <div className="about__text">
                    Производитель покрытий AvantGarde – ЗАО «Эмпилс», один из ведущих игроков на российском рынке лакокрасочных материалов. Компания также производит лакокрасочные материалы на органической и водной основе под марками Newtone, «Ореол», «Дисконт», «Титан», «Расцвет», «Эмпилс», «Простокрашено!» и Profiwood. Продукция компании представлена более чем в 80 регионах России, также осуществляются экспортные поставки в Армению, Беларусь, Грузию, Казахстан, Кыргызстан, Монголию, Таджикистан и Туркменистан.
                </div>
            </div>
        </div>
    )
};

export default About;
