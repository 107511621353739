import React, {Component} from 'react';
import ProtectorModal from '../protector-modal';
import './index.sass';

class Protector extends Component {
    constructor (props) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    componentDidMount() {
        document.addEventListener('keydown', this.pressEsc);
    }

    componentWillUnmount(){
        document.removeEventListener('keydown', this.pressEsc);
    }

    pressEsc = event => {
        return (event.keyCode === 27 && this.state.showModal) ? this.handleCloseModal() : '';
    };

    handleOpenModal = () => {
        this.setState({ showModal: true });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    render() {
        return (
            <React.Fragment>
                <div className="protector">
                    <div className="protector__wrapper">
                        <div
                           onClick={this.handleOpenModal}
                           className="protector__banner" />
                    </div>
                </div>

                <ProtectorModal
                    showModal={this.state.showModal}
                    onClose={this.handleCloseModal}/>

            </React.Fragment>
        );
    }
}
export default Protector;
