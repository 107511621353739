import React, { Component } from 'react';

class Logo extends Component {
    constructor(props) {
        super(props);
        this.logo = React.createRef();
        this.redLogo = React.createRef();
    }

    initAnimation() {
        const redLogo = this.redLogo.current;
        const logo = this.logo.current;
        setTimeout(function() {
            redLogo.classList.add('active');
        },2800);
        setTimeout(function() {
            logo.classList.add('active');
        },500)
    }

    componentDidMount() {
        this.initAnimation();
    }

    render() {
        return(
            <React.Fragment>
                <div className="initial-screen__logo" ref={this.logo}/>
                <div className="initial-screen__red-logo" ref={this.redLogo}>
                    Avantgarde
                </div>
            </React.Fragment>
        );
    }

}

export default Logo;