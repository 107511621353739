import React, {Component} from 'react';
import ReactModal from 'react-modal';
import './index.sass';

class ProtectorModal extends Component {
    render() {
        return (
            <ReactModal isOpen={this.props.showModal}>
                <button onClick={this.props.onClose} className="modal__close" />
                <div className="modal">
                    <div className="modal__header">
                        <div className="modal__header-future">Будущее – рядом!</div>
                        <div className="modal__header-title">Серия Protector</div>
                        <div className="modal__header-subtitle">В разработке</div>
                    </div>

                    <div className="modal__body">
                        <div className="modal__item modal__item--interior">
                            <div className="modal__item-image">
                                <div className="modal__item-bg"/>
                                <div className="modal__item-tank" />
                            </div>
                            <div className="modal__item-title">
                                Protector для интерьеров
                            </div>
                            <div className="modal__item-desc">
                                Перспективный продукт премиального сегмента, не имеющий прямых аналогов на рынке. Инновационное покрытие для интерьеров будущего.
                            </div>
                            <div className="modal__item-features">
                                <div className="modal__item-feature">
                                    <img src={require('./img/icons/bio.svg')} alt=""/>
                                    <span>Безопасен для здоровья: гипоаллергенен, <br/>без резкого запаха</span>
                                </div>

                                <div className="modal__item-feature">
                                    <img src={require('./img/icons/noise.svg')} alt=""/>
                                    <span>Шумоподавление</span>
                                </div>

                                <div className="modal__item-feature">
                                    <img src={require('./img/icons/magnet.svg')} alt=""/>
                                    <span>Защита от электромагнитного излучения</span>
                                </div>

                                <div className="modal__item-feature">
                                    <img src={require('./img/icons/electric.svg')} alt=""/>
                                    <span>Защита от статического электричества</span>
                                </div>

                                <div className="modal__item-feature">
                                    <img src={require('./img/icons/smell.svg')} alt=""/>
                                    <span>Дезактивация вредных веществ <br/>и неприятных запахов</span>
                                </div>
                            </div>
                        </div>

                        <div className="modal__item modal__item--facade">
                            <div className="modal__item-image">
                                <div className="modal__item-bg" />
                                <div className="modal__item-tank" />
                            </div>
                            <div className="modal__item-title">
                                Protector для фасадов
                            </div>
                            <div className="modal__item-desc">
                                Перспективный продукт премиального сегмента, не имеющий прямых аналогов на рынке. Новое поколение покрытий для фасадов с уникальными свойствами.
                            </div>
                            <div className="modal__item-features">
                                <div className="modal__item-feature">
                                    <img src={require('./img/icons/bio.svg')} alt=""/>
                                    <span>Низкое содержание ЛОС <br/>(соответствует Евродирективе VOC)</span>
                                </div>

                                <div className="modal__item-feature">
                                    <img src={require('./img/icons/heat.svg')} alt=""/>
                                    <span>Теплосбережение <br/>(для северных регионов)</span>
                                </div>

                                <div className="modal__item-feature">
                                    <img src={require('./img/icons/reflection.svg')} alt=""/>
                                    <span>Светоотражение <br/>(для южных регионов)</span>
                                </div>

                                <div className="modal__item-feature">
                                    <img src={require('./img/icons/steam.svg')} alt=""/>
                                    <span>Паропроницаемость</span>
                                </div>

                                <div className="modal__item-feature">
                                    <img src={require('./img/icons/clarity.svg')} alt=""/>
                                    <span>Самоочищение</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ReactModal>
        );
    }
}
export default ProtectorModal;
