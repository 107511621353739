import React from 'react';
import './index.sass';
import data from '../../data.json';
import CatalogItem from '../catalog-item';

function loadData() {
    let arr = [];
    let result = JSON.parse(JSON.stringify(data.catalog));
    for (let i in result) {
        arr.push(result[i]);
    }
    return arr;
}

const resultArray = loadData(); // массив итемов

const Catalog = () => {
    return (
        <div className="catalog">
            <div className="catalog__wrapper">
                {resultArray.map(item => <CatalogItem key={item.id} info={item}/>)}
            </div>
        </div>
    );
};

export default Catalog;
